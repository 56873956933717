import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://034ef1473c0d5b7157bcc9e7ad24d587@o4506773172649984.ingest.us.sentry.io/4506773318402048",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});